<script>

    export let image = ''
    export let background_gradient = 'linear-gradient(to bottom, #91248C, #000000)';
    import {base} from "$app/paths";

      // Reusable action to fade elements in when they enter the viewport
  function fade_in_on_scroll(node) {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Directly apply the fade transition here or add a class to trigger CSS animations
          node.style.opacity = 1;
          node.style.transform = 'none';
          observer.unobserve(node); // Optional: Stop observing after the element is visible
        }
      });
    }, { threshold: [0.1] });

    observer.observe(node);

    // Initial style to start from (e.g., faded out and slightly translated)
    node.style.opacity = 0;
    node.style.transform = 'translateY(100px)';
    node.style.transition = 'opacity 1s ease-out, transform 2s ease-out';

    return {
      destroy() {
        observer.unobserve(node);
      }
    };
  }
  
 
 
 </script>
 
 <div use:fade_in_on_scroll class="demo centered-content " >
     <img src={image} alt="teacher" class="demo" width="30%"/>
     <div class="demo-box" style="background-image: {background_gradient};">
         <h1 style="text-align: center; margin-top: 3vh;">Teacher</h1>
         <p>Would you like instant help grading your student's homework</p>
 
         <div use:fade_in_on_scroll class="step">
 
             <div class="text">
                 <div class="number">  <h1 class="number" style="font-size: 20vh;">1</h1></div>
               
                 <p style="font-size: 1.5vw; ">Instructor uploads <strong style="color: #B57AFF;">rubric.</strong></p>
 
             </div>
             <div class="step-img">
                 <img src={`${base}/images/16.png`} alt="step" width="50%">
                 
             </div>
         </div>
 
         <div use:fade_in_on_scroll class="line" ></div>
 
         <div use:fade_in_on_scroll class="step">
 
             <div class="text">
                 <div class="number">  <h1 class="number" style="font-size: 20vh;">2</h1></div>
               
                 <p style="font-size: 1.5vw; ">Instructor’s rubric is <strong style="color: #B57AFF;">checked.</strong> </p>
 
             </div>
             <div class="step-img">
                 <img src={`${base}/images/17.png`} alt="step" width="50%">
                 
             </div>
         </div>
         <div use:fade_in_on_scroll class="line" ></div>
 
         <div use:fade_in_on_scroll class="step">
 
             <div  class="text">
                 <div class="number">  <h1 class="number" style="font-size: 20vh;">3</h1></div>
               
                 <p style="font-size: 1.5vw; ">Student uploads <strong style="color: #B57AFF;">problem</strong>.</p>
 
             </div>
             <div class="step-img">
                 <img src={`${base}/images/upload.png`} alt="step" width="50%">
                 
             </div>
         </div>

         <div use:fade_in_on_scroll class="line" ></div>

         <div use:fade_in_on_scroll class="step">
 
            <div class="text">
                <div class="number">  <h1 class="number" style="font-size: 20vh;">4</h1></div>
              
                <p style="font-size: 1.5vw; ">Student's problem <strong style="color: #B57AFF;">is graded</strong>.</p>

            </div>
            <div class="step-img">
                <img src={`${base}/images/grade.png`} alt="step" width="50%">
                
            </div>
        </div>

        <div class="side-by-side"><div use:fade_in_on_scroll class="line" ></div></div>

        <div use:fade_in_on_scroll class="step">
 
            <div class="text">
                <div class="number">  <h1 class="number" style="font-size: 20vh;">5</h1></div>
              
                <p style="font-size: 1.5vw; ">After assignment is due student receives <strong style="color: #B57AFF;">corrections</strong>.</p>

            </div>
            <div class="step-img">
                <img src={`${base}/images/correction.png`} alt="step" width="50%">
                
            </div>
        </div>

        <div use:fade_in_on_scroll class="line" ></div>

        <div use:fade_in_on_scroll class="step">
 
            <div class="text">
                <div class="number">  <h1 class="number" style="font-size: 20vh;">6</h1></div>
              
                <p style="font-size: 1.5vw; ">Your grades are <strong style="color: #B57AFF;">handled </strong>for you.</p>

            </div>
            <div class="step-img">
                <img src={`${base}/images/last.png`} alt="step" width="50%">
                
            </div>
        </div>
 
     </div>
 </div>
 
 <style>
  .side-by-side{
    display: flex;
  }
 
  .demo-box{
     width: 80vw;
     height: auto;
     padding: 25px;
     border-radius: 5vw;
 
 
 
  }
 
  .line{
     height: 25vh;
     width: 0.5vw;
     background-color: white;
     border-radius: 3vw;
     margin-left: 12vw;
  }
 
  .text{
     display: flex;
     gap: 3vw;
     justify-content: center;
     align-items: center;
     text-align: left;
    
  }
 
  .centered-content {
   display: flex;
   flex-direction: column; /* Stack items vertically */
   align-items: center; /* Center items horizontally */
   justify-content: center; /* Center items vertically */
   text-align: center; /* Ensures text within children is also centered, if needed */
  } 
 
  .step{
     display: flex;
     justify-content: space-between;
     align-items: center;
     gap: 5vw;
     margin: 1vh 10vw 0 10vw;
  }
 
  .step-img{
     text-align: right;
  }
  
  @media (max-width: 600px) {

   .step{
    flex-direction: column;
   }

   h1{
    font-size: 10px;
   }

}
 
  
     
     
     
 </style>