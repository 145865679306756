<script>

  export let image = ''
  export let h1 = 'Student'
  export let p = ''
  export let backgroundGradient = 'linear-gradient(to bottom, #605DF9, #000000)';
  export let imageStep = ''
  import {base} from "$app/paths";



  function fade_in_on_scroll(node) {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Directly apply the fade transition here or add a class to trigger CSS animations
          node.style.opacity = 1;
          node.style.transform = 'none';
          observer.unobserve(node); // Optional: Stop observing after the element is visible
        }
      });
    }, { threshold: [0.1] });

    observer.observe(node);

    // Initial style to start from (e.g., faded out and slightly translated)
    node.style.opacity = 0;
    node.style.transform = 'translateY(100px)';
    node.style.transition = 'opacity 1s ease-out, transform 2s ease-out';

    return {
      destroy() {
        observer.unobserve(node);
      }
    };
  }


</script>

<div class="demo centered-content " >
  <img src={image} alt="picture of the {h1}" class="demo-img" width="30%"/>
  <div class="demo-box" style="background-image: {backgroundGradient};">
    <h1 style="text-align: center; margin-top: 3vh;">{h1}</h1>
    <p>Would you like instant help grading your homework</p>

    <div use:fade_in_on_scroll class="step">

      <div class="text">
        <div class="number"> <h1 class="number" style="font-size: 20vh;">1</h1></div>

        <p style="font-size: 1.5vw; ">You upload your <strong style="color: #B57AFF;">handwriting </strong> work</p>

      </div>
      <div class="step-img">
        <img src={`${base}/images/16.png`} alt="step" width="50%">

      </div>
    </div>

    <div use:fade_in_on_scroll class="line" ></div>

    <div use:fade_in_on_scroll class="step">

      <div class="text">
        <div class="number"> <h1 class="number" style="font-size: 20vh;">2</h1></div>

        <p style="font-size: 1.5vw; ">You receive the <strong style="color: #B57AFF;">mistakes</strong> you've made.</p>

      </div>
      <div class="step-img">
        <img src={`${base}/images/17.png`} alt="step" width="50%">

      </div>
    </div>
    <div use:fade_in_on_scroll class="line" ></div>

    <div use:fade_in_on_scroll class="step">

      <div class="text">
        <div class="number"> <h1 class="number" style="font-size: 20vh;">3</h1></div>

        <p style="font-size: 1.5vw; ">You <strong style="color: #B57AFF;">correct</strong> your work.</p>

      </div>
      <div class="step-img">
        <img src={`${base}/images/18.png`} alt="step" width="50%">

      </div>
    </div>

  </div>
</div>

<style>


 .demo-box{
  width: 80vw;
  height: auto;
  padding: 25px;
  border-radius: 5vw;



 }

 .line{
  height: 25vh;
  width: 0.5vw;
  background-color: white;
  border-radius: 3vw;
  margin-left: 12vw;
 }

 .text{
  display: flex;
  gap: 3vw;
  justify-content: center;
  align-items: center;
  text-align: left;

 }

 .centered-content {
 display: flex;
 flex-direction: column; /* Stack items vertically */
 align-items: center; /* Center items horizontally */
 justify-content: center; /* Center items vertically */
 text-align: center; /* Ensures text within children is also centered, if needed */
 } 

 .step{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5vw;
  margin: 1vh 10vw 0 10vw;
 }

 .step-img{
  text-align: right;
 }
 

 



</style>